<template>
	<div class="navbar-tbf-landing">
		<div class="logo" @mouseover="logoAnim">
			<lottie class="bird-logo active" :options="defaultOptionsLogo" :height="59" :width="48" v-on:animCreated="handleAnimationLogo"/>
		</div>
		<div class="actions">
			<template v-if="$auth.check()">
				<div class="profile">
					<img :src="$auth.user().avatar" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-if="$auth.user().avatar">
					<div class="user-circle" ref="dropdownUser" id="dropdownUser" data-toggle="dropdown" v-else>
						<icon-user />
					</div>

					<div class="dropdown-menu dropdown-menu-right" id="dropdownApplications" aria-labelledby="dropdownApplications" v-if="$auth.ready()">
						<div class="user-data">
							<div class="name">{{ $auth.user().first_name + ' ' + $auth.user().last_name }}</div>
							<div class="email">{{ $auth.user().email }}</div>
						</div>
						<div class="dropdown-item">
							<router-link :to="{name: 'dashboard' }">
								<div class="text">{{ $t('navbar.dashboard') }}</div>
							</router-link>
						</div>
						<div class="dropdown-item">
							<div class="text" @click="$auth.logout()" >{{ $t('navbar.log_out') }}</div>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="login-register">
					<router-link :to="{name: 'login' }"><div class="text">Login</div></router-link>/
					<router-link :to="{name: 'register' }"><div class="text">Register</div></router-link>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import Lottie from 'vue-lottie';
	import * as animationDataLogo from '../../assets/animations/tbfdigital_logo.json';
	import IconUser from '../Icons/User'

	export default {
		data() {
			return {
				defaultOptionsLogo: {
					animationData: animationDataLogo.default,
					autoplay: false,
					loop: false,
				},
				finished: true
			};
		},
		components: {
			IconUser,
			Lottie
		},
		mounted() {
			this.$auth.load().then(() => {
				if(this.$auth.check()){
					// this.initWebSocket()
				}
			});
		},
		methods: {
			handleAnimationLogo(anim){
				this.anim_logo = anim;
				this.anim_logo.setSpeed(1);
			},
			logoAnim(){
				if(this.finished && !this.bird_noti){
					this.finished = false;
					this.anim_logo.play();
					setTimeout(() => {
						this.anim_logo.stop();
						this.finished = true;
					}, 5000);
				}
			}
		}
	};
</script>